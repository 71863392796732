export const user = {
  get: '/api/users',
  add: '/api/auth/register',
  update: '/api/users',
  delete: '/api/users'
};

// export const auth = {
//   authenticate: 'api/auth/login'
// };

export const certificates = {
  getList: '/api/contents/certificates',
  get: '/api/contents/certificates',
  add: '/api/contents/certificates',
  update: '/api/contents/certificates',
  delete: '/api/contents/certificates'
};

export const contacts = {
  getList: '/api/contacts',
  get: '/api/contacts',
  add: '/api/contacts',
  update: '/api/contacts',
  delete: '/api/contacts'
};

export const clients = {
  getList: '/api/clients',
  get: '/api/clients',
  add: '/api/clients',
  update: '/api/clients',
  delete: '/api/clients'
};

export const services = {
  getList: '/api/services',
  get: '/api/services',
  add: '/api/services',
  update: '/api/services',
  delete: '/api/services'
};

export const feedbacks = {
  getList: '/api/feedbacks',
  get: '/api/feedbacks',
  add: '/api/feedbacks',
  update: '/api/feedbacks',
  delete: '/api/feedbacks'
};

export const news = {
  getList: '/api/news',
  get: '/api/news',
  add: '/api/news',
  update: '/api/news',
  delete: '/api/news'
};

export const info = {
  getList: '/api/infos',
  get: '/api/infos'
};

export const auth = {
  authenticate: 'api/auth/login',
  forgotPassword: 'api/auth/forgot',
  changePassword: 'api/auth/change',
  valid: '/api/auth/valid'
};

export const settings = {
  updateUser: '/api/user/update',
  changePassword: '/api/user/change/password'
};