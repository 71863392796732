import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Breads from 'src/components/Breads';

const Page = forwardRef(({ children, title = '', ...rest }, ref) => {
  return (
    <Fade in={true}>
      <div ref={ref} {...rest}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Container maxWidth="lg">
          <Breads />
        </Container>
        {children}
      </div>
    </Fade>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
