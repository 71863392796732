import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import NotFoundView from 'src/views/errors/NotFoundView';
import SettingsView from 'src/views/settings/SettingsView';

const DashboardLayout = lazy(() =>
  import(/*webpackChunkName: "dashboard" */ 'src/layouts/DashboardLayout')
);

const LoginView = lazy(() =>
  import(/*webpackChunkName: "login" */ 'src/views/auth/LoginView')
);

const ForgotView = lazy(() =>
  import(/*webpackChunkName: "forgot" */ 'src/views/auth/ForgotView')
);

const UserListView = lazy(() =>
  import(/*webpackChunkName: "userListView" */ 'src/views/users/UserListView')
);

const UserView = lazy(() =>
  import(/*webpackChunkName: "userView" */ 'src/views/users/UserView')
);

const UserAddView = lazy(() =>
  import(/*webpackChunkName: "userAddView" */ 'src/views/users/UserAddView')
);



// CERTIFICATE

const CertificateListView = lazy(() =>
  import(
    /*webpackChunkName: "certificateListView" */ 'src/views/certificate/CertificateListView'
  )
);
const CertificateView = lazy(() =>
  import(
    /*webpackChunkName: "certificateView" */ 'src/views/certificate/CertificateView'
  )
);
const CertificateAddView = lazy(() =>
  import(
    /*webpackChunkName: "certificateAddView" */ 'src/views/certificate/CertificateAddView'
  )
);

// INFO

const InfoListView = lazy(() =>
  import(
    /*webpackChunkName: "infoListView" */ 'src/views/info/InfoListView'
  )
);
const InfoView = lazy(() =>
  import(
    /*webpackChunkName: "infoView" */ 'src/views/info/InfoView'
  )
);
// const InfoAddView = lazy(() =>
//   import(
//     /*webpackChunkName: "infoAddView" */ 'src/views/info/InfoAddView'
//   )
// );

// SERVICE

const ServiceListView = lazy(() =>
  import(
    /*webpackChunkName: "serviceListView" */ 'src/views/service/ServiceListView'
  )
);
const ServiceView = lazy(() =>
  import(
    /*webpackChunkName: "serviceView" */ 'src/views/service/ServiceView'
  )
);
const ServiceAddView = lazy(() =>
  import(
    /*webpackChunkName: "serviceAddView" */ 'src/views/service/ServiceAddView'
  ));

// NEWS

const NewsListView = lazy(() =>
  import(
    /*webpackChunkName: "newsListView" */ 'src/views/news/NewsListView'
  )
);
const NewsView = lazy(() =>
  import(
    /*webpackChunkName: "newsView" */ 'src/views/news/NewsView'
  )
);
const NewsAddView = lazy(() =>
  import(
    /*webpackChunkName: "newsAddView" */ 'src/views/news/NewsAddView'
  ));

// CLIENT

const ClientListView = lazy(() =>
  import(
      /*webpackChunkName: "clientListView" */ 'src/views/client/ClientListView'
  )
);
const ClientView = lazy(() =>
  import(
      /*webpackChunkName: "clientView" */ 'src/views/client/ClientView'
  )
);
const ClientAddView = lazy(() =>
  import(
      /*webpackChunkName: "clientAddView" */ 'src/views/client/ClientAddView'
  ));

// FEEDBACK

const FeedbackListView = lazy(() =>
  import(
    /*webpackChunkName: "feedbackListView" */ 'src/views/feedback/FeedbackListView'
  )
);
const FeedbackView = lazy(() =>
  import(
    /*webpackChunkName: "feedbackView" */ 'src/views/feedback/FeedbackView'
  )
);
const FeedbackAddView = lazy(() =>
  import(
    /*webpackChunkName: "feedbackAddView" */ 'src/views/feedback/FeedbackAddView'
  )
);

// CONTACT

const ContactListView = lazy(() =>
  import(
    /*webpackChunkName: "contactListView" */ 'src/views/contact/ContactListView'
  )
);
const ContactView = lazy(() =>
  import(
    /*webpackChunkName: "contactView" */ 'src/views/contact/ContactView'
  )
);
const ContactAddView = lazy(() =>
  import(
    /*webpackChunkName: "contactAddView" */ 'src/views/contact/ContactAddView'
  )
);

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'user', element: <UserListView /> },
      { path: 'user/register', element: <UserAddView /> },
      { path: 'user/:id', element: <UserView /> },
      { path: 'certificate', element: <CertificateListView /> },
      { path: 'certificate/:id', element: <CertificateView /> },
      { path: 'certificate/register', element: <CertificateAddView /> },
      { path: 'info', element: <InfoListView /> },
      { path: 'info/:id', element: <InfoView /> },
      { path: 'service', element: <ServiceListView /> },
      { path: 'service/:id', element: <ServiceView /> },
      { path: 'service/register', element: <ServiceAddView /> },
      { path: 'news', element: <NewsListView /> },
      { path: 'news/:id', element: <NewsView /> },
      { path: 'news/register', element: <NewsAddView /> },
      { path: 'feedback', element: <FeedbackListView /> },
      { path: 'feedback/:id', element: <FeedbackView /> },
      { path: 'feedback/register', element: <FeedbackAddView /> },
      { path: 'contact', element: <ContactListView /> },
      { path: 'contact/:id', element: <ContactView /> },
      { path: 'contact/register', element: <ContactAddView /> },
      { path: 'client', element: <ClientListView /> },
      { path: 'client/:id', element: <ClientView /> },
      { path: 'client/register', element: <ClientAddView /> },
      { path: 'dashboard', element: <Navigate to="/app/user" /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'login', element: <LoginView /> },
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '', element: <LoginView /> },
      // { path: '/login', element: <LoginView /> },
      { path: '/forgot', element: <ForgotView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/app', element: <Navigate to="/app/user" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
