import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';

i18n
  .use(LanguageDetector)
  .use(XHR)
  .init({
    backend: {
      loadPath: '/translations/{{lng}}/{{ns}}.json'
    },
    debug: false, //process.env.NODE_ENV === 'development',
    lng: 'mn',

    fallbackLng: 'mn',
    ns: ['common', 'auth', 'alert'],
    defaultNS: 'common',
    keySeparator: '.',
    nsSeparator: ':',

    interpolation: {
      escapeValue: false
    },

    // react: {
    //   wait: true,
    //   useSuspense: false
    // }
  });

export default i18n;
