import { settings } from 'src/services/config';
import axios from 'axios';

class SettingsService {
  updateUser = data =>
    new Promise((resolve, reject) => {
      axios
        .post(settings.updateUser, data)
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  changePassword = data =>
    new Promise((resolve, reject) => {
      axios
        .post(settings.changePassword, data)
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  forgotPassword = data =>
    new Promise((resolve, reject) => {
      axios
        .post(settings.forgotPassword, data)
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
}

const instance = new SettingsService();
export default instance;
