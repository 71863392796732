import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import ProgressButton from 'src/components/ProgressButton';
import { useTranslation } from 'react-i18next';
import SettingsService from 'src/services/settings.service';
import { useSnackbar } from 'material-ui-snackbar-provider';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const snackbar = useSnackbar();
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    username: '',
    companyName: ''
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
      ? JSON.parse(localStorage.getItem('user'))
      : null;
    setUser(user);
  }, []);

  const handleClickOnSave = async () => {
    try {
      setLoading(true);
      await SettingsService.updateUser(user);
      setLoading(false);
      localStorage.setItem('user', JSON.stringify(user));
      snackbar.showMessage('Амжилттай', t('close'), () => { });
    } catch (error) {
      snackbar.showMessage(error.response.data.message, t('close'), () => { });
      setLoading(false);
    }
  };

  const handleChange = event => {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader={t('profile.instruction')}
          title={t('profile.title')}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                disabled
                label={t('profile.username')}
                name="username"
                onChange={handleChange}
                required
                value={user.username}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('profile.company')}
                name="companyName"
                onChange={handleChange}
                required
                value={user.companyName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('profile.firstname')}
                name="firstName"
                onChange={handleChange}
                required
                value={user.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('profile.lastname')}
                name="lastName"
                onChange={handleChange}
                required
                value={user.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('profile.email')}
                name="email"
                onChange={handleChange}
                required
                value={user.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('profile.phone')}
                name="phone"
                onChange={handleChange}
                type="number"
                value={user.phone}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <ProgressButton
            color="primary"
            variant="contained"
            onClick={handleClickOnSave}
            loading={loading}
            text={loading ? t('profile.saving') : t('profile.save')}
          ></ProgressButton>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
