import axios from 'axios';
let whitelist = ['auth/login'];

const SetupInterceptors = (navigate) => {
  axios.interceptors.request.use(config => {
    if (whitelist.find(e => config.url.includes(e))) return config;
    if (localStorage.getItem('user')) {
      let token = JSON.parse(localStorage.getItem('user')).accessToken;
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      localStorage.clear();
      navigate("/");
    }
  });

  axios.interceptors.response.use(
    response => {
      return response;
    },
    err => {
      if (err.response.status === 403 || err.response.status === 401) {
        // history.push("app/login")
        // history.replace("login");
        localStorage.clear();
        navigate("/");
        // window.location.reload();
      }
      return Promise.reject(err);
    }
  );
}

// axios.interceptors.request.use(function (config) {
//   return config;
// }, function (error) {
//   return Promise.reject(error);
// });

// axios.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//   return Promise.reject(error);
// });
export default SetupInterceptors;