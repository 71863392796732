import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { Suspense, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { LinearProgress, ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment';
import i18n from 'src/config/translation';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import 'src/services/interceptor';

const App = () => {
  const routing = useRoutes(routes);
  useEffect(() => {
    moment.locale(i18n.language);
    // eslint-disable-next-line
  }, [i18n.language]);
  return (
    <Suspense fallback={<LinearProgress />}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider SnackbarProps={{ autoHideDuration: 3000 }}>
            <GlobalStyles />
            {routing}
          </SnackbarProvider>
        </ThemeProvider>
      </I18nextProvider>
    </Suspense>
  );
};

export default App;
