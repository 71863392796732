import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ProgressButton from 'src/components/ProgressButton';
import SettingsService from 'src/services/settings.service';
import { useSnackbar } from 'material-ui-snackbar-provider';

const useStyles = makeStyles({
  root: {}
});

const Password = ({ className, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [values, setValues] = useState({
    password: '',
    username: JSON.parse(localStorage.getItem('user')).username,
    newPassword: '',
    repPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleClickOnSave = async () => {
    try {
      setLoading(true);
      await SettingsService.changePassword(values);
      setLoading(false);
      snackbar.showMessage('Амжилттай', t('close'), () => { });
    } catch (error) {
      snackbar.showMessage(error.response.data.message, t('close'), () => { });
      setLoading(false);
    }
  };

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          subheader={t('password.instruction')}
          title={t('password.title')}
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label={t('auth:password')}
            margin="normal"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label={t('auth:new_password')}
            margin="normal"
            name="newPassword"
            onChange={handleChange}
            type="password"
            value={values.newPassword}
            variant="outlined"
          />
          <TextField
            fullWidth
            label={t('auth:rep_password')}
            margin="normal"
            name="repPassword"
            onChange={handleChange}
            type="password"
            value={values.repPassword}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <ProgressButton
            color="primary"
            variant="contained"
            onClick={handleClickOnSave}
            loading={loading}
            text={loading ? t('profile.saving') : t('profile.save')}
          ></ProgressButton>
        </Box>
      </Card>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
