import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useLocation, useNavigate } from 'react-router-dom';
import history from 'src/config/history';

const titles = [
  { path: 'user', label: 'Хэрэглэгч' },
  { path: 'news', label: 'Мэдээ мэдээлэл' },
  { path: 'service', label: 'Үйлчилгээ' },
  { path: 'feedback', label: 'Сэтгэгдэл' },
  { path: 'certificate', label: 'Сертификат' },
  { path: 'contact', label: 'Хүсэлт' },
  { path: 'client', label: 'Харилцагч' }
];

const StyledBreadcrumb = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    height: theme.spacing(3),
    color: theme.palette.grey[1200],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[400]
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[400], 0.12)
    }
  }
}))(Chip);

const Breads = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let currentRoutes = [];

  const getBreadTitle = path => {
    let title = titles.find(title => {
      return title.path === path;
    });
    return title ? title.label : 'Дэлгэрэнгүй';
  };

  currentRoutes = location.pathname !== '/' ? location.pathname.split('/') : [];
  if (currentRoutes.length > 0) {
    currentRoutes.shift();
    return (
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        style={{ marginBottom: 15 }}
      >
        {/* <StyledBreadcrumb
          label="Нүүр"
          // color="inherit"
          onClick={() => navigate('/app/user')}
          style={{ cursor: 'pointer' }}
        /> */}
        {currentRoutes.length === 1 ? (
          <Typography color="textPrimary">
            {/* {getBreadTitle(currentRoutes[0])} */}
          </Typography>
        ) : (
          currentRoutes.map((route, index) => {
            return index !== currentRoutes.length - 1 ? (
              index === 0 ? (
                ''
              ) : (
                <StyledBreadcrumb
                  label={getBreadTitle(route)}
                  key={index}
                  color="inherit"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    let link = '/app';
                    for (let i = 1; i <= index; i++) {
                      link = link + '/' + currentRoutes[i];
                    }
                    navigate(link);
                  }}
                />
              )
            ) : (
              <Chip
                key={index}
                size="small"
                label={getBreadTitle(route)}
                deleteIcon={<ArrowBack />}
                onDelete={() => history.back()}
                onClick={() => history.back()}
              />
            );
          })
        )}
      </Breadcrumbs>
    );
  } else return <></>;
};

export default Breads;
