import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Password from './Password';
import ProfileDetails from './ProfileDetails';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  return (
    <Page className={classes.root} title={t('settings.title')}>
      <Container maxWidth="lg">
        <ProfileDetails />
        {/* <Box mt={3}>
          <Notifications />
        </Box> */}
        <Box mt={3}>
          <Password />
        </Box>
      </Container>
    </Page>
  );
};

export default SettingsView;
