import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import history from 'src/config/history';
import SetupInterceptors from './services/interceptor';


function NavigateFunctionComponent(props) {
  let navigate = useNavigate();
  const [ran, setRan] = useState(false);

  if (!ran) {
    SetupInterceptors(navigate);
    setRan(true);
  }
  return <></>;
}

ReactDOM.render(
  <BrowserRouter history={history}>
    {<NavigateFunctionComponent />}
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
